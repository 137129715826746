import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import plakat from "../images/pout2.png";
import device from "../css/styledComp";
const customStyles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    display: "flex",
    flexDirection: "column",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  },
};

const Aktualita = ({ modalIsOpen, closeModal }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Div>
        <h2>AKTUALITA</h2>
        {/* <p>Vážení klienti,<br /> rádi bychom vás pozvali na kapelu Blue Grass,</p>
        <p>
        která bude hrát od 20 hodin 29. června. */}
        {/* </p> */}
        {/* <p>
        POZOR!<br/> Dne 5. června budeme mít z důvodu úprav otevřeno až od 17 hodin. 
        </p>
        <p>Těšíme se na Vás, <br /> tým Arabela restaurant.</p> */}

        <Img src={plakat} alt="pozvanka na akci" />
        <button className="btn shadow" onClick={() => closeModal()}>
          zavřít
        </button>
      </Div>
    </Modal>
  );
};

export default Aktualita;

const Img = styled.img`
  margin: auto;
  margin-bottom: 1rem;
  width: 650px;
  height: 550px;
  @media ${device.tablet} {
    width: 450px;
    height: 450px;
  }
  @media ${device.mobileL} {
    width: 310px;
    height: 360px;
  }
  @media (max-width: 1024px) {
    width: 400px;
    height: 400px;
  }
  z-index: 20;
`;

const Div = styled.div`
  width: 850px;
  height: max-content;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    width: 600px;
    p {
      font-size: 18px;
    }
  }
  @media ${device.mobileL} {
    width: 310px;
    p {
      font-size: 15px;
    }
  }
  @media (max-width: 1024px) {
    width: 500px;
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 20px;
    }
  }
`;
