import React from "react";
import device from "../css/styledComp";
import styled from "styled-components";
import { MdLocationPin } from "react-icons/md";
import { AiFillPhone, AiFillFacebook } from "react-icons/ai";
import { HiMail } from "react-icons/hi";
import { BsCreditCardFill } from "react-icons/bs";
import foto from "../images/background_bottom.jpg";
// import foto from "../images/background_bottom2.jpg";
const Contact = () => {
  return (
    <Wrapper id="kontakt">
      <div
        className="single-box aos-init aos-animate shadow"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="500"
      >
        <h2>Kontakt</h2>
        <div className="contact">
          <div className="info-box">
            <div>
              <MdLocationPin className="item" />
            </div>
            <div>
              <p>Areál Autokempu, Konopáč 37, 538 03 Heřmanův Městec</p>
            </div>
          </div>
          <div className="info-box">
            <div>
              <AiFillPhone className="item" />
            </div>
            <div>
              <p>+420 608 927 097</p>
            </div>
          </div>
          <div className="info-box">
            <div>
              <HiMail className="item" />
            </div>
            <div>
              <p>arabelakonopac@gmail.com</p>
            </div>
          </div>
          <div className="info-box">
            <div>
              <BsCreditCardFill className="item" />
            </div>
            <div>
              <p>platba hotově i kartou</p>
            </div>
          </div>
          <div className="info-box">
            <div>
              <AiFillFacebook className="item" />
            </div>
            <div>
              <p>
                <a href="https://www.facebook.com/arabelakonopac/">
                  Arabela Konopáč
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="single-box aos-init aos-animate shadow"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="700"
      >
        <h2>Otevírací doba</h2>
        <ul>
          <li>
            Pondělí <span> 11-22</span>
            <hr />
          </li>
          <li>
            Uterý <span> 11-22</span>
            <hr />
          </li>
          <li>
            Středa <span> 11-22</span>
            <hr />
          </li>
          <li>
            Čtvrtek <span> 11-22</span>
            <hr />
          </li>
          <li>
            Pátek <span>11-22</span>
            <hr />
          </li>
          <li>
            Sobota <span>11-22</span>
            <hr />
          </li>
          <li>
            Neděle <span>11-22</span>
            <hr />
          </li>
        </ul>
      </div>
      <div
        className="single-box map aos-init aos-animate shadow"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="900"
      >
        <iframe
          className="map1"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.9558493982577!2d15.644372317443846!3d49.937169700000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c4ba0fd9b6873%3A0x3df25ab8f8bf42d7!2sArabela%20restaurant!5e0!3m2!1scs!2scz!4v1656057971377!5m2!1scs!2scz"
          width="100%"
          height="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <a
          href="https://www.google.com/maps/dir//Arabela+restaurant,+Konop%C3%A1%C4%8D+37,+538+03+He%C5%99man%C5%AFv+M%C4%9Bstec/@49.9371697,15.6443723,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x470c4ba0fd9b6873:0x3df25ab8f8bf42d7!2m2!1d15.646561!2d49.9371697!3e0?hl=cs-CZ"
          className="btn"
          target="_blank"
        >
          {" "}
          Vyhledat adresu
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* margin: 1.2rem; */
  background-image: url(${foto});
  background-position: center;
  background-size: cover;
  background: cover;
  /* border-radius: 2rem; */
  padding: 2rem;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* align-items: stretch; */
  /* align-content: center; */
  .contact {
    /* margin-top: 1rem; */
  }
  .info-box {
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
    padding: 0.5rem;
    margin: auto;
  }
  p {
    padding-left: 2rem;
    font-size: 1.2rem;
    color: white;
    text-align: start;
  }
  h2 {
    text-align: center;
  }
  a {
    text-decoration: underline;
    color: white;
  }
  .header-box {
    text-align: center;
    padding-bottom: 3rem;
  }
  .item {
    color: var(--main-color);
    font-size: 2rem;
  }
  .main-menu-box {
    text-align: center;
  }
  .single-box {
    display: flex;
    flex-direction: column;
    background-color: var(--second-color);
    width: 25%;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem;
    /* height: 400px; */
  }

  .map {
    position: relative;
    padding: 0;
    min-height: 100%;
    overflow: hidden;
  }
  ul {
    margin: 0 1rem 1rem 1rem;
  }
  li {
    font-size: 1.3rem;
    width: 100%;
    color: white;
    padding: 0.2rem;
    text-align: start;
  }
  span {
    float: right;
  }
  .map1 {
    border: none;
  }
  .btn {
    position: absolute;
    z-index: 5;
    bottom: 20px;
    padding: 1.2rem;
    font-size: 1rem;
    border-radius: 1rem;
    background: var(--second-color);
    color: white;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
  .btn:hover {
    background-color: #762729;
  }
  @media ${device.laptopL} {
    .single-box {
      width: 350px;
    }
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.1rem;
    }
    .item {
      font-size: 2rem;
    }
  }
  @media ${device.laptopM} {
    .single-box {
      width: 350px;
    }
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
    .item {
      font-size: 1.5rem;
    }
  }
  @media ${device.laptop} {
    .single-box {
      width: 300px;
    }
    a {
      font-size: 0.8rem;
    }
    .btn {
      font-size: 0.8rem;
    }
    li {
      font-size: 1.1rem;
    }
    p {
      font-size: 0.9rem;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    .item {
      font-size: 1.3rem;
    }
  }
  @media ${device.tab800} {
    display: flex;
    flex-direction: column;

    align-items: center;
    .single-box {
      width: 400px;
      padding: 0.5rem;
    }
    a {
      font-size: 0.8rem;
    }
    .btn {
      font-size: 0.6rem;
    }
    li {
      font-size: 0.8rem;
    }
    p {
      font-size: 1rem;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    .item {
      font-size: 1.5rem;
    }
    .map {
      padding: 0;
    }
  }
  @media ${device.mobileML} {
    .single-box {
      width: 300px;
      padding: 0.5rem;
    }
    a {
      font-size: 0.8rem;
    }
    .btn {
      font-size: 0.6rem;
    }
    li {
      font-size: 0.8rem;
    }
    p {
      font-size: 1rem;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    .item {
      font-size: 1.5rem;
    }
    .map {
      padding: 0;
    }
  }
  @media ${device.mobileS} {
    .single-box {
      width: 280px;
      padding: 0.5rem;
    }
    a {
      font-size: 0.8rem;
    }
    .btn {
      font-size: 0.6rem;
      padding: 1rem 1rem;
    }
    li {
      font-size: 0.8rem;
    }
    p {
      font-size: 1rem;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    .item {
      font-size: 1.5rem;
    }
    .map {
      padding: 0;
    }
  }
  @media ${device.mobileSS} {
    .single-box {
      width: 260px;
      padding: 0.5rem;
    }
    a {
      font-size: 0.8rem;
    }
    .btn {
      font-size: 0.6rem;
      padding: 1rem 1rem;
    }
    li {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.8rem;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    .item {
      font-size: 1.5rem;
    }
    .map {
      padding: 0;
    }
  }
`;
export default Contact;
