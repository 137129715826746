const size = {
    mobileSS: "280px",
    mobileS: "325px",
    mobileM: "375px",
    mobileL: "425px",
    mobileML: "460px",
    tab525: "525px",
    tab650: "680px",
    tab750: "750px",
    tablet: "750px",
    tab800: "800px",
    laptop: "1024px",
    laptopM: "1280px",
    laptopL: "1440px",
    s1600: "1600px",
    desktop: "2560px",
};

const device = {
    mobileSS: `(max-width: ${size.mobileSS})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    mobileML: `(max-width: ${size.mobileML})`,
    tab525: `(max-width: ${size.tab525})`,
    tab650: `(max-width: ${size.tab650})`,
    tab750: `(max-width: ${size.tab750})`,
    tab800: `(max-width: ${size.tab800})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    laptopM: `(max-width: ${size.laptopM})`,
    s1600: `(max-width: ${size.s1600})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};

export default device;
