import React from "react";
import logo from "../images/logo.png";
import { social } from "../util/socialBtns";
import "../css/Navbar.css";
import { AiOutlineMenu } from "react-icons/ai";
import { SIDEBAR_INFO } from "../reducers/action";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const { sidebar } = useSelector((state) => state.basic);
  const dispatch = useDispatch();
  return (
    <div className={`${sidebar ? "sidebar show-sidebar" : "sidebar"}`}>
      <div className="sidebar-header">
        <img
          src={logo}
          className="nav-logo"
          alt="coding addict"
          onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
        />
        <button
          className="close-btn"
          onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
        >
          <AiOutlineMenu style={{ border: "none" }} />
        </button>
      </div>
      <ul className="links">
        <li>
          {/* <a
            href="#actions"
            onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
          >
            Akce
          </a> */}
        </li>
        <li>
          <a
            href="#about"
            onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
          >
            O nás
          </a>
        </li>
        <li>
          <a
            href="#menu"
            onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
          >
            Denní menu
          </a>
        </li>
        <li>
          <a
            href="#partners"
            onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
          >
            Partneři
          </a>
        </li>
        {/* <li>
                    <a
                        href="#galerie"
                        onClick={() =>
                            dispatch({ type: SIDEBAR_INFO, payload: false })
                        }
                    >
                        Jídelní lístek
                    </a>
                </li> */}
        <li>
          <a
            href="#kontakt"
            onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
          >
            Kontakt
          </a>
        </li>
        {social.map((link) => {
          const { id, url, icon } = link;
          return (
            <li
              key={id}
              onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
            >
              <a href={url}>{icon}</a>
            </li>
          );
        })}
      </ul>
      {/* <ul className="social-icons">
                {social.map((link) => {
                    const { id, url, icon } = link;
                    return (
                        <li
                            key={id}
                            onClick={() =>
                                dispatch({ type: SIDEBAR_INFO, payload: false })
                            }
                        >
                            <a href={url}>{icon}</a>
                        </li>
                    );
                })}
            </ul> */}
    </div>
  );
};

export default Sidebar;
