import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo2 from "../images/logo.png";
import device from "../css/styledComp";
import background from "../images/background.jpeg";
import About from "../components/About";
import Contact from "../components/Contact";
import Carousell from "../components/Carousell";
import { ImArrowUp } from "react-icons/im";
import MainMenu from "../components/MainMenu";
import Modal from "react-modal";
import Aktualita from "../components/Aktualita";
import Contact2 from "../components/Contact2";

Modal.setAppElement("#root");
const Header = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(true);
  const [arrow, setArrow] = useState("arrow-to-top arrow-01");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    document.getElementById("header").style.zIndex = "2";
    console.log((document.getElementById("header").style.zIndex = 2));
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setArrow("arrow-to-top arrow-01 show");
        return;
      } else {
        setArrow("arrow-to-top arrow-01 ");
      }
    };
    window.addEventListener("scroll", handleScroll);
    openModal();
       document.getElementById("header").style.zIndex = "2";
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {}, [modalIsOpen]);
  return (
    <div className="modal">
      <>
        {/* <Aktualita modalIsOpen={modalIsOpen} closeModal={closeModal} /> */}
        <HeaderMain className="header-08">
          <div className="header-container" id="header">
            <div className="logo-container">
              {/* <h1> Hello mister</h1> */}
              <img
                src={logo2}
                alt="preflop-mage-logo"
                draggable="false"
                className="main-logo animate__animated animate__fadeIn"
              />

              <h4 className="animate__animated animate__fadeInDown animate__delay-1s color">
                Nejen výletní restaurace
              </h4>
            </div>
          </div>
          {/* <a href="#about" className="mousey">
            <div className="scroller"></div>
          </a>
          <div className={`${arrow} random`}>
            <a href="#main">
              <ImArrowUp className="arrow" />
            </a>
          </div> */}
        </HeaderMain>
        {/* <Actions /> */}
        <About />
        <Carousell setSpinner={setSpinner} />
        {/* <Carousel /> */}
        {/* <Menu /> */}
        <MainMenu />
        {/* <FoodBox /> */}
        <Contact />
        <Contact2 />
      </>
    </div>
  );
  // }
};

const HeaderMain = styled.header`
  h1 {
    font-size: 5rem;
  }
  h4 {
    font-size: 2.5rem;
  }
  .arrow-to-top.arrow-01.show {
    opacity: 1;
    pointer-events: all;
    bottom: 30px;
  }
  .modal {
    position: relative;
  }
  .arrow {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: white;
  }
  .random a {
    position: relative;
    width: 45px;
    height: 45px;
  }
  .arrow:hover {
    top: 55%;
  }
  .arrow-to-top.arrow-01 a:hover {
    background-color: #ffc315;
  }
  .arrow-to-top.arrow-01 {
  cursor: pointer;
  pointer-events: none; /* Disable pointer events by default */
  position: fixed;
  text-align: center;
  z-index: 100;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  right: 40px;
  bottom: 10px;
  opacity: 0;
}
  .arrow-to-top.arrow-01 a {
    text-decoration: none;
    padding: 5px 12px;
    color: #ffffff;
    background: var(--main-color);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 50%);
    box-shadow: 0 0 2px rgb(0 0 0 / 50%);
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    border-radius: 8px;
    font-size: 1.2rem;
    display: inline-block;
  }

  .header-container {
    /* z-index: 2; */
    overflow: hidden;
    /* margin: 1.2rem; */
    /* border-radius: 2rem; */
    display: flex;
    background: url(${background});
    position: relative;

    background-position: center;
    background-size: cover;
    height: 100%;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.45);
      z-index: -1;
    }
  }

  h1,
  h4 {
    color: white;
    /* color: var(--main-color); */
  }
  a {
    transition: 0.3s all;
    text-decoration: none;
  }

  .main-logo {
    height: 55%;
    width: 75%;
    opacity: 1;
    margin: 2rem;
  }
  .logo-container {
    width: 100vh;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .extra {
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
  }
  @media ${device.tab800} {
    /* display: flex; */
    h1 {
      font-size: 2.3rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    a {
      transition: 0.3s all;
      text-decoration: none;
    }

    .main-logo {
      height: 50%;
      width: 80%;
      opacity: 1;
      margin: 1.5rem;
    }
    .logo-container {
      width: 100vh;
      height: 100vh;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .extra {
      padding: 1rem;
      border-radius: 1rem;
      margin: 1rem;
    }
  }
  @media ${device.tab650} {
    h1 {
      font-size: 1.8rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    a {
      transition: 0.3s all;
      text-decoration: none;
    }

    .main-logo {
      height: 50%;
      width: 80%;
      opacity: 1;
      margin: 1.5rem;
    }
    .logo-container {
      width: 100vh;
      height: 100vh;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .extra {
      padding: 1rem;
      border-radius: 1rem;
      margin: 1rem;
    }
  }
  @media ${device.tab525} {
    h1 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    a {
      transition: 0.3s all;
      text-decoration: none;
    }

    .main-logo {
      height: 50%;
      width: 80%;
      opacity: 1;
      margin: 1.5rem;
    }
    .logo-container {
      width: 100vh;
      height: 100vh;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .extra {
      padding: 1rem;
      border-radius: 1rem;
      margin: 1rem;
    }
  }
  @media ${device.mobileL} {
    h1 {
      font-size: 1.2rem;
    }
    h4 {
      font-size: 1rem;
    }
    a {
      transition: 0.3s all;
      text-decoration: none;
    }

    .main-logo {
      height: 35%;
      width: 80%;
      opacity: 1;
      margin: 1.5rem;
    }
    .logo-container {
      width: 100vh;
      height: 100vh;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .extra {
      padding: 0.7rem;
      border-radius: 1rem;
      margin: 1rem;
    }
  }
  @media ${device.mobileS} {
    h1 {
      font-size: 0.95rem;
    }
    h4 {
      font-size: 0.55rem;
    }
    a {
      transition: 0.3s all;
      text-decoration: none;
    }

    .main-logo {
      height: 40%;
      width: 70%;
      opacity: 1;
      margin: 1.5rem;
    }
    .logo-container {
      width: 100vh;
      height: 100vh;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .extra {
      padding: 0.7rem;
      border-radius: 1rem;
      margin: 1rem;
    }
  }
`;

export default Header;
