import React from "react";
import styled from "styled-components";
import device from "../css/styledComp";
const Footer = () => {
    return (
        <Wrapper>
            <div className="box">
                <h5>
                    &copy; {new Date().getFullYear()}
                    <span> Arabela Restaurant</span>
                </h5>
                <h5>All rights reserved</h5>
            </div>
            <div className="box">
                <h5> Provozovatel: Bushman bar service s.r.o.</h5>
                <h5>Adresa: Konopáč 37, 538 03 Heřmanův Městec </h5>
            </div>
            <div className="box">
                <h5>E-mail: arabelakonopac@gmail.com</h5>
                <h5>Plátci DPH CZ9043250</h5>
            </div>
        </Wrapper>
    );
};
const Wrapper = styled.footer`
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--second-color);
    text-align: start;
    padding: 20px;
    span {
        color: var(--main-color);
    }
    .box {
        padding: 10px;
        margin: auto;
        text-align: center;
    }
    h5 {
        color: var(--clr-white);
        margin: 0.1rem;

        font-weight: 400;
        text-transform: none;
        line-height: 1.25;
    }
    @media (min-width: 776px) {
        flex-direction: row;
    }
    @media ${device.laptop} {
        h5 {
            font-size: 0.75rem;
        }
        height: max-content;
    }
`;
export default Footer;
