import { SIDEBAR_INFO } from "./action";

const initialState = {
    login: "",
    sidebar: false,
};

const reducer = (state = initialState, action) => {
    if (action.type === SIDEBAR_INFO) {
        return { ...state, sidebar: action.payload };
    }
    return state;
};
export default reducer;
