import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import React, { useEffect, useState } from "react";
import "animate.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useParams,
    useLocation,
    useMatch,
} from "react-router-dom";
import Header from "./pages/Header";
import Login from "./pages/Login";
import Preloader from "./components/Preloader";
import Error from "./pages/Error";
import CookieConsent from "react-cookie-consent";
function App() {
    const [data, setData] = useState("");

    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="Souhlasím"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#451819" }}
                buttonStyle={{
                    color: "black",
                    fontSize: "15px",
                    borderRadius: "2rem",
                    padding: "0.8rem",
                }}
                expires={150}
            >
                Používáním našeho webu souhlasíte s naší <a>Cookie Policy.</a>
                {/* <span style={{ fontSize: "10px" }}>
                    This bit of text is smaller :O
                </span> */}
            </CookieConsent>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Header />} />
                    {/* <Route path="/products" element={<PreflopMage />} /> */}
                    <Route path="/login" element={<Login />} />

                    <Route path="*" element={<Error />} />
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;
