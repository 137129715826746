import logger from "redux-logger";

import storage from "redux-persist/lib/storage";

import basic from "./BasicReducer.js";

import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import persistStore from "redux-persist/es/persistStore";
import { applyMiddleware, legacy_createStore as createStore } from "redux";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["filter"],
};

const reducers = combineReducers({ basic });

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, applyMiddleware(logger, thunk));

const persistor = persistStore(store);

export default store;

export { persistor };
