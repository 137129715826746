import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import device from "../css/styledComp";
import OneMeal from "../components/OneMeal";
import { Link, useNavigate } from "react-router-dom";
import { onValue } from "firebase/database";
import { set, ref } from "firebase/database";
import { db } from "../util/firebase";
const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [denniMenu, setDenniMenu] = useState("");
    const navigate = useNavigate();
    const onSubmit = (e) => {
        console.log(e);

        if (e.username === "bushman" && e.password === "Pardubice62") {
            setIsLoggedIn(true);
            return;
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = "Vyžadováno";
        }
        if (!values.password) {
            errors.password = "Vyžadováno";
        }

        return errors;
    };
    console.log("CO JE", denniMenu);
    useEffect(() => {}, [isLoggedIn]);
    useEffect(() => {
        onValue(ref(db), (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
                setDenniMenu(Object.values(data.food));
            }
        });
    }, []);
    return (
        <Wrapper>
            {isLoggedIn ? (
                <div>
                    <h1> Jste přihlášen </h1>
                    <button
                        className="btn shadow"
                        onClick={() => navigate("/")}
                    >
                        {" "}
                        zpátky na web
                    </button>
                    <div className="oneMeal">
                        <OneMeal
                            name="polevka"
                            type="soup"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="polevka2"
                            type="soup"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní1"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní2"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní3"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní4"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní5"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní6"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní7"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní8"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní9"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavní91"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavníStale1"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavníStale2"
                            type="main"
                            denniMenu={denniMenu}
                        />
                        <OneMeal
                            name="hlavníStale3"
                            type="main"
                            denniMenu={denniMenu}
                        />
                    </div>
                </div>
            ) : (
                <div className="login">
                    <Form
                        onSubmit={onSubmit}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <h1>Login</h1>
                                <div>
                                    <Field name="username">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Username</label>
                                                <input
                                                    {...input}
                                                    type="text"
                                                    placeholder="Username"
                                                    className="box"
                                                />
                                                {meta.error && meta.touched && (
                                                    <span>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div>
                                    <Field name="password">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Password</label>
                                                <input
                                                    {...input}
                                                    className="box"
                                                    type="password"
                                                    placeholder="Password"
                                                />
                                                {meta.error && meta.touched && (
                                                    <span>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <button type="submit" className="btn">
                                    Submit
                                </button>
                            </form>
                        )}
                    />
                </div>
            )}
            <button className="btn shadow margin" onClick={() => navigate("/")}>
                {" "}
                zpátky na web
            </button>
        </Wrapper>
    );
};

export default Login;
const Wrapper = styled.div`
    z-index: 2;
    overflow: hidden;
    margin: 1.2rem;
    border-radius: 2rem;
    background-color: var(--second-color);
    height: max-content;
    text-align: center;
    padding: 2rem;
    padding-top: 6rem;
    input {
        margin: 5px;
        height: 35px;
    }
    .oneMeal {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
    }
    .margin {
        margin: 1rem;
    }
    .box {
        height: 2rem;
        margin: 1rem;
    }
    .login {
        background: var(--background);
        margin: auto;
        padding: 3rem;
        width: max-content;
        border-radius: 2rem;
    }
    @media ${device.laptop} {
        .oneMeal {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media ${device.laptop} {
        .oneMeal {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media ${device.tab650} {
        .oneMeal {
            grid-template-columns: repeat(1, 1fr);
        }
    }
`;
