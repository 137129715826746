import React from "react";
import styled from "styled-components";
import logo from "../images/logo.png";
const Preloader = () => {
    return (
        <div className="loader">
            <img src={logo} alt="logo" />
            <h2>už se to připravuje ... </h2>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

const Wrapper = styled.div`
    background-color: black;
    height: 90vh;
    width: 100%;
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 300px;
    }
`;
export default Preloader;
