import React from "react";
import styled from "styled-components";
import camp from "../images/logoKemp.png";
import logo from "../images/logo copy.png";
import bushmanBar from "../images/bushman bar group-1.png";
const Contact2 = () => {
  return (
    <Wrapper>
      <div className="box" id="partners">
        <h3>Partneři</h3>
        <div className="underline"></div>
        <div className="content">
          <a href="https://konopac-hm.cz/" target="_blank">
            <img src={camp} />
          </a>
          <a href="https://www.bushmanbarservice.cz/" target="_blank">
            <img src={logo} />
          </a>
          <a href="https://www.facebook.com/BushmanPardubice" target="_blank">
            <img src={bushmanBar} />
          </a>
          {/* <img src={}/> */}
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  padding: 5rem 0;

  h3 {
    text-transform: none;
    color: var(--main-color);
  }
  img {
    max-inline-size: 200px;
    border-radius: 0;
  }
  img:hover {
    transform: scale(1.1);
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    margin-top: 2rem;
  }
  .underline {
    width: 5rem;
    height: 0.25rem;
    color: var(--main-color);
    background-color: var(--main-color);
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8rem;
      /* margin-top: 2rem; */
    }
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1280px) {
    padding: 8rem 0;
  }
`;

export default Contact2;
