import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Error = () => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <div className="container">
                <h1>Tato stránka neexistuje</h1>
                <div>
                    <button
                        className="btn shadow"
                        onClick={() => navigate("/")}
                    >
                        {" "}
                        zpátky na web
                    </button>
                </div>
            </div>
        </Wrapper>
    );
};

export default Error;

const Wrapper = styled.div`
    height: 90vh;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin: auto;
    }
`;
