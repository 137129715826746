import React from "react";

const Menu = ({ items, denniMenu }) => {
  if (items.length) {
    return (
      <div className="section-center">
        {items &&
          items?.map((menuItem, index) => {
            const { name, weight, price } = menuItem;
            return (
              <article key={index} className="menu-item shadow">
                {/* <img src={img} alt={title} className="photo" /> */}
                <div className="item-info">
                  <header>
                    <h4>{name}</h4>
                    <p className="item-text">{price},-</p>
                  </header>
                  <h4 className="price">{weight}</h4>
                </div>
              </article>
            );
          })}
      </div>
    );
  }
  return (
    <div className="section-center">
      {denniMenu &&
        denniMenu?.map((menuItem, index) => {
          const { name, weight, price } = menuItem;
          return (
            <article key={index} className="menu-item ">
              {/* <img src={img} alt={title} className="photo" /> */}
              <div className="item-info">
                <header>
                  <h4>{name}</h4>
                  <p className="item-text">{price},-</p>
                </header>
                <h4 className="price">{weight}</h4>
              </div>
            </article>
          );
        })}
    </div>
  );
};

export default Menu;
