import React from "react";
import styled from "styled-components";
import foto from "../images/image_carousel3.jpeg";
import steak from "../images/steak.jpg";
import background from "../images/background.jpeg";
import foto2 from "../images/home.jpg";
import foto3 from "../images/home1.jpg";
import device from "../css/styledComp";
const About = () => {
    return (
        <Wrapper>
            <div className="text-box" id="about">
                <h1>O nás</h1>
                <p>
                    Naše restaurace v malebném prostředí podhůří Železných hor
                    je ideálním místem pro Vaši rodinnou oslavu, svatbu, firemní
                    akci, školení, ale i&nbsp;místem, kam si můžete zajít na
                    oběd či příjemnou večeři. Zakládáme si na poctivé české i
                    světové domácí kuchyni, zejména klademe důraz na připravu
                    pokrmů z kvalitních surovin bez použití polotovarů.
                </p>
                <p>
                    Náš bar je bohatě zásoben a vinotéku máme plnou kvalitních
                    vín. Zajistíme pro Vás raut, dovezeme jídlo pro Vaše
                    zaměstnance nebo Vám připravíme jídlo s sebou.
                </p>
                <p>
                    Širokou nabídku našich služeb doplňuje i
                    možnost&nbsp;ubytování v&nbsp;Autokempu Konopáč (kde se
                    nachází i naše restaurace).
                </p>
                <p>
                    Nezapoměnte nás sledovat i na našich sociálních sítích, ať
                    Vám nic neunikne:{" "}
                    <a
                        href="https://www.facebook.com/arabelakonopac"
                        className="underline"
                    >
                        {" "}
                        facebook
                    </a>{" "}
                    a
                    <a
                        href="https://www.facebook.com/arabelakonopac"
                        className="underline"
                    >
                        {" "}
                        instagram
                    </a>
                    .
                </p>
                <h4>Těšíme se na Vaši návštěvu.</h4>
            </div>
            <div className="image-container">
                <img src={foto3} alt="" className="image-box shadow" />
                {/* <div className="image-block"></div> */}
            </div>
        </Wrapper>
    );
};

export default About;
const Wrapper = styled.div`
    display: flex;
    /* margin: 0 1.2rem 1.2rem 1.2rem; */
    background: var(--second-color);
    /* border-radius: 2rem; */
    padding: 2rem;
    /* margin-top: 3rem; */
    /* height: 100%; */
    height: max-content;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    span {
        color: var(--main-color);
    }
    h1,
    h4 {
        color: var(--main-color);
        text-align: start;
    }
    h4 {
        margin-top: 10px;
    }
    img:hover {
        transform: rotate(+1deg);
    }
    .underline:hover {
        color: var(--clr-bonus);
    }
    .text-box {
        display: flex;
        flex-direction: column;
        /* justify-content: flex-start; */
        align-content: flex-start;
        /* align-items: center; */
        /* margin: auto; */

        width: 600px;
        padding: 2rem;
        margin-left: 6rem;

        margin-right: auto;
        z-index: 5;
        color: var(--main-color);
        flex-grow: 1;
        /* position: absolute; */
    }
    p {
        color: white;
        font-size: 1.15rem;
        text-align: start;
    }
    .underline {
        text-decoration: underline;
        color: var(--main-color);
    }
    .image-container {
        flex-grow: 1;
        position: relative;
        display: flex;
        /* border: 3px solid var(--second-color); */
        height: 100%;
        /* width: 100%; */
        justify-content: center;
        align-items: center;
        padding: 2rem;
    }

    .image-box {
        border-radius: 4rem;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        max-width: 795px;
        height: 420px;
        /* margin-top: 5rem; */

        z-index: 5;
        /* width: 45%; */
        /* border-bottom-right-radius: 2rem;
        border-top-right-radius: 2rem;
        padding: 1rem; */
    }
    /* .image-box:hover {
        transform: scale(1.01);
        transform: rotate(5deg);
        transition: all 0.3s ease-in-out;
    } */
    /* .image-block:hover {
        transform: scale(1.01);
        transform: rotate(5deg);
        transition: all 0.3s ease-in-out;
    } */
    @media ${device.s1600} {
        .image-box {
            height: 390px;
        }
        p {
            font-size: 1rem;
        }
    }
    @media ${device.laptopL} {
        .image-container {
            /* width: 40%; */
        }
        .image-box {
            height: 320px;
        }
    }
    @media ${device.laptopM} {
        .image-container {
            width: max-content;
        }
        .image-box {
            width: 500px;
            height: 320px;
        }

        p {
            font-size: 0.9rem;
        }
    }

    @media ${device.laptop} {
        display: flex;
        flex-direction: column;

        padding: 0;
        overflow: hidden;
        margin: auto;
        justify-content: center;
        align-items: center;
        .image-container {
            display: flex;
            justify-content: center;
            /* align-items: center; */

            margin-bottom: 1rem;
        }
        .image-box {
            width: 600px;
            height: 420px;
            margin: auto;
        }

        .image-block {
            width: 0;
        }
        p {
            font-size: 0.9rem;
            margin-bottom: 5px;
            text-align: center;
        }
        .text-box {
            justify-content: center;

            padding: 0.2rem;
            align-items: center;
            display: flex;
            margin: auto;
        }
    }
    @media ${device.tab650} {
        display: flex;
        flex-direction: column;
        /* height: 85vh; */
        padding: 0;
        overflow: hidden;
        .image-container {
            height: max-content;
        }
        .image-box {
            width: 500px;
            height: 350px;
            margin: auto;
            /* top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); */
        }

        .image-block {
            width: 0;
        }
        p {
            font-size: 0.9rem;
        }
        .text-box {
            justify-content: center;
            margin: auto;
            padding: 0.2rem;
            align-items: center;
            /* padding-right: 7rem;
            padding-left: 2rem; */
            display: flex;
        }
        .text-box {
            display: flex;
            flex-direction: column;
            width: 100%;

            justify-content: flex-start;
            align-items: center;
            align-content: center;
            margin: auto;
            justify-content: center;
            align-items: center;
            padding: 20px;
        }
    }
    @media ${device.tab525} {
        display: flex;
        flex-direction: column;

        padding: 0;
        overflow: hidden;

        .image-box {
            width: 450px;
            height: 300px;
            margin: auto;
        }

        p {
            font-size: 0.75rem;
        }
    }
    @media ${device.mobileML} {
        display: flex;
        flex-direction: column;
        height: max-content;
        padding: 0;
        overflow: hidden;

        .image-box {
            width: 400px;
            height: 300px;
            margin: auto;
        }

        p {
            font-size: 0.8rem;
        }
    }
    @media ${device.mobileM} {
        display: flex;
        flex-direction: column;

        padding: 0;
        overflow: hidden;

        .image-box {
            width: 350px;
            height: 250px;
            margin: auto;
        }

        p {
            font-size: 0.8rem;
        }
    }
    @media ${device.mobileSS} {
        display: flex;
        flex-direction: column;

        padding: 0;
        overflow: hidden;
        height: max-content;
        .image-box {
            width: 275px;
            height: 250px;
            margin: auto;
        }

        p {
            font-size: 0.8rem;
        }
        h4 {
            text-align: center;
        }
    }
`;
