import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import device from "../css/styledComp";
// import logo from "../images/background.jpeg";
import logo2 from "../images/slavky.jpg";
import logo3 from "../images/image_carousel2.jpeg";
import logo from "../images/rizek.jpg";
import logo4 from "../images/image_carousel3.jpeg";
import logo5 from "../images/steak.jpg";
import logo6 from "../images/background_tortila.jpg";
import logo_only from "../images/logo_only.png";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1440 },
        items: 5,
    },
    notebook: {
        breakpoint: { max: 1440, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 520 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 520, min: 0 },
        items: 1,
    },
};
const Carousell = () => {
    return (
        <Wrapper>
            <div className="container">
                <h2>Kvalitní kuchyně na dosah ruky </h2>
                <hr className="style-eight" />
            </div>
            <div className="carousel">
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    // customTransition="all .5"
                    transitionDuration={500}
                    autoPlay={true}
                    autoPlaySpeed={2250}
                    // itemClass="carousel"
                    centerMode={true}
                >
                    <div>
                        <img src={logo} className="carousel-box" />
                    </div>
                    <div>
                        <img src={logo2} className="carousel-box" />
                    </div>
                    <div>
                        <img src={logo3} className="carousel-box" />
                    </div>
                    <div>
                        <img src={logo4} className="carousel-box" />
                    </div>
                    <div>
                        <img src={logo5} className="carousel-box" />
                    </div>
                    <div>
                        <img src={logo6} className="carousel-box" />
                    </div>
                    {/* <div>Item 2</div>
                <div>Item 3</div>
            <div>Item 4</div> */}
                </Carousel>
                <div className="custom-shape-divider-bottom-1658216600">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
                            className="shape-fill"
                        ></path>
                    </svg>
                </div>
            </div>
        </Wrapper>
    );
};

export default Carousell;

const Wrapper = styled.div`
    background-color: var(--second-color);
    /* margin: 1.1rem; */
    text-align: center;
    /* padding: 2rem; */
    /* padding-top: 5rem; */
    /* height: 750px; */
    h1 {
        /* margin-bottom: 3rem; */
        /* text-decoration: underline; */
    }
    hr {
        margin-bottom: 1rem;
        color: var(--main-color);
    }
    .container {
        width: max-content;
        /* text-align: center; */
        margin: auto;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .carousel-box {
        width: 400px;
        height: 300px;
        border-radius: 50%;
        z-index: 5;
        /* margin: 2rem; */

        /* background-color: white;
        padding: 1rem; */
    }
    .carousel {
        background-color: var(--main-color);
        z-index: 5;
        padding: 2rem;
        position: relative;
        height: 500px;
    }
    @media ${device.laptop} {
        .container {
            font-size: 1.1rem;
        }
        h2 {
            font-size: 2rem;
        }
    }
    hr.style-eight {
        overflow: visible; /* For IE */
        padding: 0;
        border: none;
        border-top: medium double #f3c641;
        /* color: #f3c641; */
        text-align: center;
    }
    hr.style-eight:after {
        content: url(${logo_only});
        display: inline-block;
        position: relative;
        top: -0.7em;
        font-size: 1.5em;
        padding: 0 0.25em;
        background: var(--second-color);
    }
    .custom-shape-divider-bottom-1658216600 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1658216600 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 100px;
    }

    .custom-shape-divider-bottom-1658216600 .shape-fill {
        fill: var(--second-color);
    }
    @media ${device.laptopL} {
        padding-top: 1rem;
        .container {
            margin-top: 2rem;
        }
    }
    @media ${device.laptop} {
        .container {
            margin-top: 0rem;
        }
    }
    @media ${device.tab650} {
        height: max-content;
        h2 {
            font-size: 1.5rem;
        }
        .carousel {
            height: 400px;
        }
        .carousel-box {
            width: 300px;
            height: 250px;
            border-radius: 50%;
            z-index: 20;
        }
    }
    @media ${device.mobileML} {
        h2 {
            font-size: 1rem;
        }

        .carousel {
            height: 350px;
        }
        .carousel-box {
            width: 250px;
            height: 200px;
            border-radius: 50%;
            z-index: 20;
        }
        hr.style-eight:after {
            content: url(${logo_only});
            display: inline-block;
            position: relative;
            top: -0.7em;
            font-size: 1rem;
            padding: 0 0.25em;
            background: var(--second-color);
        }
    }
    @media ${device.mobileS} {
        h2 {
            font-size: 1rem;
        }

        .carousel {
            height: 350px;
        }
        .carousel-box {
            width: 200px;
            height: 150px;
            border-radius: 50%;
            z-index: 20;
        }
        hr.style-eight:after {
            content: url(${logo_only});
            display: inline-block;
            position: relative;
            top: -0.7em;
            font-size: 1rem;
            padding: 0 0.25em;
            background: var(--second-color);
        }
    }
    @media ${device.mobileSS} {
        h2 {
            font-size: 0.8rem;
        }

        .carousel {
            height: 350px;
        }
        .carousel-box {
            width: 200px;
            height: 150px;
            border-radius: 50%;
            z-index: 20;
        }
        hr.style-eight:after {
            content: url(${logo_only});
            display: inline-block;
            position: relative;
            top: -0.7em;
            font-size: 1rem;
            padding: 0 0.25em;
            background: var(--second-color);
        }
    }
`;
