import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { set, ref, push, update, remove } from "firebase/database";
import { db } from "../util/firebase";
const OneMeal = ({ name, type, denniMenu }) => {
    const [first, setFirst] = useState(false);
    const [data, setData] = useState("");

    const onSubmit = (e) => {
        console.log(e);
        const data = Object.keys(e);
        if (data.length < 3) {
            updateDatabase(e?.weight, e?.name, e?.price);
            return;
        }
        writeToDatabase(e?.weight, e?.name, e?.price);
    };
    console.log("menu", denniMenu, type, name);
    // console.log("TYPE a NAME", type, name);
    useEffect(() => {
        selectRightOne(denniMenu);
    }, [denniMenu]);
    // console.log(denniMenu[0], "TADY");
    const selectRightOne = (denniMenu) => {
        if (type === "soup" && denniMenu) {
            const selection = denniMenu[4];
            const info = Object?.entries(selection);
            const data = info?.find((item) => item[0] === name);
            setData(data);

            return;
        }
        if (type === "main" && denniMenu) {
            const selection = denniMenu[1];
            const info = Object?.entries(selection);
            const data = info?.find((item) => item[0] === name);
            // console.log("CO TOHLE ", info);
            console.log("CO TOHLE ", data);
            setData(data);
            return;
        }
    };
    const updateDatabase = (weight, food, price) => {
        if (!weight && !food) {
            update(ref(db, `/food/${type}/${name}`), {
                price,
            });
            return;
        }
        if (!weight && !price) {
            update(ref(db, `/food/${type}/${name}`), {
                food,
            });
            return;
        }
        if (!price && !food) {
            update(ref(db, `/food/${type}/${name}`), {
                weight,
            });
            return;
        }
        if (!food) {
            update(ref(db, `/food/${type}/${name}`), {
                weight,
                price,
            });
            return;
        }
        if (!weight) {
            update(ref(db, `/food/${type}/${name}`), {
                food,
                price,
            });
            return;
        }
        if (!price) {
            update(ref(db, `/food/${type}/${name}`), {
                food,
                weight,
            });
            return;
        }
    };
    const handleDelete = () => {
        remove(ref(db, `/food/${type}/${name}`));
    };
    const writeToDatabase = (weight, food, price) => {
        set(ref(db, `/food/${type}/${name}`), {
            weight,
            food,
            price,
        });
    };
    if (data) {
        return (
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <h1>{name}</h1>
                        <div>
                            <Field name="weight">
                                {({ input, meta }) => (
                                    <div>
                                        <label>Váha</label>
                                        <input
                                            {...input}
                                            type="text"
                                            placeholder={data[1]?.weight}
                                            className="box"
                                        />
                                        {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div>
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div>
                                        <label>Název</label>
                                        <input
                                            {...input}
                                            type="text"
                                            placeholder={data[1]?.food}
                                            className="box"
                                        />
                                        {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div>
                            <Field name="price">
                                {({ input, meta }) => (
                                    <div>
                                        <label>Cena</label>
                                        <input
                                            {...input}
                                            className="box"
                                            type="number"
                                            placeholder={data[1]?.price}
                                        />
                                        {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <button
                            type="submit"
                            className="btn"
                            onClick={setFirst(true)}
                        >
                            Uložit
                        </button>
                        <button
                            type="submit"
                            className="btn"
                            style={{ background: "red" }}
                            onClick={() => handleDelete()}
                        >
                            Smazat
                        </button>
                    </form>
                )}
            />
        );
    }
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <h1>{name}</h1>
                    <div>
                        <Field name="weight">
                            {({ input, meta }) => (
                                <div>
                                    <label>Váha</label>
                                    <input
                                        {...input}
                                        type="text"
                                        className="box"
                                    />
                                    {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                    )}
                                </div>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field name="name">
                            {({ input, meta }) => (
                                <div>
                                    <label>Název</label>
                                    <input
                                        {...input}
                                        type="text"
                                        className="box"
                                    />
                                    {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                    )}
                                </div>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field name="price">
                            {({ input, meta }) => (
                                <div>
                                    <label>Cena</label>
                                    <input
                                        {...input}
                                        className="box"
                                        type="number"
                                    />
                                    {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                    )}
                                </div>
                            )}
                        </Field>
                    </div>

                    <button
                        type="submit"
                        className="btn"
                        onClick={setFirst(true)}
                    >
                        Uložit
                    </button>
                    {/* <button
                        type="submit"
                        className="btn"
                        style={{ background: "red" }}
                        onClick={setFirst(true)}
                        >
                        Smazat
                    </button> */}
                </form>
            )}
        />
    );
};

export default OneMeal;
