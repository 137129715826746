// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBX14AABId1zsCY_7BS6PojzS8DhstPl1E",
  authDomain: "arabela-restaurant.firebaseapp.com",
  databaseURL:
    "https://arabela-restaurant-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "arabela-restaurant",
  storageBucket: "arabela-restaurant.appspot.com",
  messagingSenderId: "763392096125",
  appId: "1:763392096125:web:1c1439a861234124cdfe14",
  measurementId: "G-6C20BNDE9V",
};

const firebaseApp =
  getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
// const storage = getStorage(firebaseApp);

export { auth };
export default db;
