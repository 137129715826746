import React from "react";

const Categories = ({
  categories,
  filterItems,
  starter,
  specials,
  dezerts,
}) => {
  // const ref = React.createRef();
  // const scroll = (scrollOffset) => {
  //     ref.current.scrollLeft += scrollOffset;
  // };
  return (
    <div className="btn-container">
      {/* <button onClick={() => scroll(-20)}>LEFT</button> */}
      {categories?.map((category, index) => {
        if (category === "Předkrmy" && starter.length < 1) {
          return;
        }
        if (category === "Pochoutky k pivu" && specials.length < 1) {
          return;
        }
        if (category === "Dezerty" && dezerts.length < 1) {
          return;
        }

        return (
          <button
            type="button"
            className="filter-btn shadow"
            key={index}
            onClick={() => filterItems(index)}
          >
            {category}
          </button>
        );
      })}
      {/* <button onClick={() => scroll(20)}>RIGHT</button> */}
    </div>
  );
};

export default Categories;
