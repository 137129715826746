import React from "react";
// import {
//     FaBehance,
//     FaFacebook,
//     FaLinkedin,
//     FaTwitter,
//     FaSketch,
//     FaInstagram,
// } from "react-icons/fa";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
export const social = [
    {
        id: 1,
        url: "https://www.facebook.com/arabelakonopac/",
        icon: <BsFacebook className="colore" style={{ border: "none" }} />,
    },
    // {
    //     id: 2,
    //     url: "https://www.twitter.com",
    //     icon: <BsTwitter className="colore" style={{ border: "none" }} />,
    // },
    {
        id: 3,
        url: "https://www.instagram.com/arabelakonopac/",
        icon: <BsInstagram className="colore" style={{ border: "none" }} />,
    },
    // {
    //     id: 4,
    //     url: "https://www.twitter.com",
    //     icon: <FaBehance />,
    // },
    // {
    //     id: 5,
    //     url: "https://www.twitter.com",
    //     icon: <FaSketch />,
    // },
];
