import React, { useState, useEffect } from "react";
import Menu2 from "./Menu2";
import Categories from "./Categories";
// import items from "./data";
import db from "../firebase";
import MyPDF from "../util/ArabelaKonopačVinnýListek.pdf";
import "../css/MainMenu.css";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
function MainMenu() {
  const [polévky, setPolevky] = useState("");
  const [denniMenu, setDenniMenu] = useState("");
  //   const [drinks, setDrinks] = useState("");
  const [dezerts, setDezerts] = useState("");
  const [sideDishes, setSideDishes] = useState("");
  const [specials, setSpecials] = useState("");
  const [starter, setStarter] = useState("");
  const currentDate = `${new Date().getDate()}.${new Date().getMonth() + 1}.`;
  const categories = [
    `Denní menu ${currentDate}`,
    "Polévky",
    "Předkrmy",
    "Pochoutky k pivu",
    "Dezerty",
    "Přílohy",
    // "nápoje",
    // "vinný lístek",
  ];
  const [menuItems, setMenuItems] = useState(denniMenu);
  const getDailyMenuData = async () => {
    let docs = await getDoc(doc(db, `dailyMenu/${currentDate}`));
    if (docs.data()) {
      let pochoutky = []
      let main = []
      console.log(docs.data().main)
      for(let i = docs.data().main.length; i >= 0 ;i--){
          const SingleFood = docs.data().main[i]
          
          if(SingleFood){
            if(SingleFood.name.includes("Smažený sýr")){
              for(let y = 0; y < docs.data().main.length;y++){
                const SingleFood = docs.data().main[y]
                if(SingleFood){
                  if(SingleFood.name.includes("Pečené vepřové koleno v pikantní marinádě")){
                    break;
                  }
                  console.log("wut",SingleFood)
                  main.push(SingleFood)
                }
              }
              break;
            }
           
            pochoutky.push(SingleFood)
          }
           
        }
      setPolevky(docs.data().soup);
      setDenniMenu(main);
      setDezerts(docs.data().dezert);
      setSideDishes(docs.data().sideDish);
      setSpecials(pochoutky);
      setStarter(docs.data().starter);
    }
  };
  useEffect(() => {
    getDailyMenuData();
  }, []);
  useEffect(() => {}, [menuItems]);
  const filterItems = (category) => {
    if (category === 0) {
      setMenuItems(denniMenu);
      return;
    }
    if (category === 1) {
      setMenuItems(polévky);
      return;
    }
    if (category === 3) {
      setMenuItems(specials);
      return;
    }
    if (category === 5) {
      setMenuItems(sideDishes);
      return;
    }
    if (category === 4) {
      setMenuItems(dezerts);
      return;
    }
    if (category === 5) {
      setMenuItems(specials);
      return;
    }
    if (category === 2) {
      setMenuItems(starter);
      return;
    }
    // if (category === 5) {
    //   setMenuItems(drinks);
    //   return;
    // }
    // if (category === 5) {
    //     setMenuItems(vines);
    //     return;
    // }
    // const newItems = selection?.filter((item) => item === category);
    // setDenniMenu(newItems);
  };
  console.log("ITEMY", menuItems);

  const Day = new Date().getDay()
  const whatToDisplay = ()=>{
    return <h5>Můžete očekávat víkendové akce které vám sdělíme s předstihem jak na webu tak sociálních sítích</h5>
    // if(Day  === (1 || 2 || 3)){
    //   return <h5>Dnes máme zavřeno</h5>
    // }else if(Day === 4){
    //   return<h5>Dnes máme otevřeno od 15:00 a máme pro vás pochoutky k pivu</h5>
    // }else{
    //   return <h5>Denní menu podáváme do 15:00 po té pro vás máme pochoutky k pivu</h5>
    // }
  }
  return (
    <main id="menu">
      <section className="menu section">
        <div className="title">
          <h2 className="header-h4">Naše menu</h2>
          <div className="underline"></div>
        </div>
        <a
          href={MyPDF}
          download="ArabelaKonopačVinnýListek"
          className="colores"
        >
          Vinný lístek ke stažení v pdf
        </a>
        {whatToDisplay()}
        
        {/* <div className="imgBox">
          <img src={menu} />
        </div> */}
        <Categories
          categories={categories}
          filterItems={filterItems}
          starter={starter}
          specials={specials}
          dezerts={dezerts}
        />
        {denniMenu ? (
           <Menu2 items={menuItems} denniMenu={denniMenu} />
          
        ) : (
          ""
          
        )}
      </section>
    </main>
  );
}

export default MainMenu;
