import React, { useEffect, useState } from "react";
import device from "../css/styledComp";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
// import { SIDEBAR_INFO, TOKEN } from "../reducers/actions";
import { AiFillCloseCircle, AiOutlineMenu } from "react-icons/ai";
import { GrFacebook, GrInstagram } from "react-icons/gr";
import Sidebar from "./Sidebar";
import { SIDEBAR_INFO } from "../reducers/action";
const Navbar = () => {
  const [mobile, setMobile] = useState(false);
  const dispatch = useDispatch();
  // const login = useSelector((state) => state?.basic?.login);
  // const info = useLocation();
  const navigate = useNavigate();
  const { sidebar } = useSelector((state) => state.basic);
  function openModal() {
    dispatch({ type: SIDEBAR_INFO, payload: true });
  }

  function closeModal() {
    dispatch({ type: SIDEBAR_INFO, payload: false });
  }
  // const logout = () => {
  //     // dispatch({ type: TOKEN, payload: "" });
  //     navigate("/");
  // };
  useEffect(() => {
    if (window.innerWidth < 1065) {
      setMobile(true);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1085) {
        setMobile(true);
      } else {
        setMobile(false);
        dispatch({ type: SIDEBAR_INFO, payload: false });
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <NavContainer id="hide">
      <div className="nav-center">
        {!mobile && (
          <ul className="nav-links">
            {/* <li>
              <a href="#actions">Akce</a>
            </li> */}
            <li>
              <a href="#about">O nás</a>
            </li>
            <li>
              <a href="#menu">Denní menu</a>
            </li>
            {/* <li>
                            <a href="#galerie">Jídelní lístek</a>
                        </li> */}
            <li>
              <a href="#kontakt">Kontakt</a>
            </li>
            <li>
              <a href="#partners">Partneři</a>
            </li>
            <li>
              <a href="https://www.facebook.com/arabelakonopac/">
                <GrFacebook
                  style={{
                    fontSize: "2rem",
                    border: "none",
                    color: "",
                  }}
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/arabelakonopac/">
                <GrInstagram
                  style={{
                    fontSize: "2rem",
                    border: "none",
                    color: "",
                  }}
                />
              </a>
            </li>
          </ul>
        )}
        {!mobile ? (
          <div
            className="right-corner-box"
            onClick={() => dispatch({ type: SIDEBAR_INFO, payload: false })}
          ></div>
        ) : (
          <div className="right-corner-box2">
            {sidebar ? (
              <AiOutlineMenu
                className="sidebar-toggle-burger"
                onClick={() =>
                  dispatch({
                    type: SIDEBAR_INFO,
                    payload: false,
                  })
                }
              />
            ) : (
              <AiOutlineMenu
                className="sidebar-toggle-burger"
                onClick={() =>
                  dispatch({
                    type: SIDEBAR_INFO,
                    payload: true,
                  })
                }
              />
            )}
          </div>
        )}

        <Sidebar />
      </div>
    </NavContainer>
  );
};

export default Navbar;
const NavContainer = styled.nav`
  position: absolute;
  top: 0px;
  padding-top: 2rem;
  transition: 0.1s;
  /* min-height: 0px; */
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--clr-primary);
  border-radius: 0 0 2rem 2rem;
  transition: all 0.3s linear;

  z-index: 8;
  .nav-logo {
    max-height: 100px;
    max-width: 150px;
    margin-top: 5px;
  }
  .sidebar-toggle-burger {
    font-size: 2rem;
    border: none;
    color: var(--main-color);
  }
  .nav-hide {
    display: none;
    justify-content: space-between;
  }
  .nav-logo:hover {
    cursor: pointer;
  }

  .nav-center {
    width: 100%;
    margin: auto;
  }
  @media ${device.laptopM} {
    .nav-center {
      width: 75%;
    }
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 175px;
      /* margin-left: -15px; */
    }
  }

  .nav-center {
    align-items: center;
    justify-content: center;
  }
  .nav-links {
    /* gap: 10px; */
    color: white;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    margin: 0 0.5rem;

    /* align-items: center; */
  }
  a {
    color: var(--clr-primary);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    align-items: center;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
  }
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--main-color);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 100%;
    left: 0;
  }

  .right-corner-box {
    display: flex;
    height: 60px;
    align-items: center;
  }
  .right-corner-box2 {
    display: flex;
    justify-content: flex-end;
  }
  .svg-box {
    height: 43px;
    width: 43px;
  }
  svg {
    border-radius: 2rem;
    margin: 5px;
    border: 1px solid black;
  }
  svg:hover {
    cursor: pointer;
  }
  .btn {
    max-height: 30px;
    border-radius: 2rem;
  }
  @media ${device.mobileM} {
    .right-corner-box2 {
      justify-content: center;
    }
  }
`;
